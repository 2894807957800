import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";
import {CoreFields} from "./fragment";  // eslint-disable-line

class Post extends Component {

    render() {
      this.props.pageContext.pageTitle = this.props.data.wpPost.Meta.title ? this.props.data.wpPost.Meta.title : this.props.data.wpPost.title;
      this.props.pageContext.date = this.props.data.wpPost.date;
      const blocks = buildBlocks(this.props.data.wpPost.blocks, {}, this.props.pageContext);

      return (
      <Layout
        meta={{
          ...this.props.data.wpPost.Meta,
          canonical: this.props.data.wpPost.uri
        }}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPost.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
          {blocks}
      </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: {eq: $id}) {
      id
      slug
      title
      uri
      date(formatString: "DD MMMM YYYY")
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage{
          uri
        }        
      }    
      ... on WpBlockEditorContentNode {
        blocks {
          ...CoreBlock
          ...CoreFields
        }
      }
    },
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
}
`;

export default Post
